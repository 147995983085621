<template>
  <div>
    <div>
      <div>
        <template>
          <div>
            <a-row>
              <a-col :lg="{ span: 6 }" :xxl="{ span: 4 }">
                <el-card :style="{ height: screenHeight + 'px' }">
                  <div style="height: 0.8rem">设备分类</div>
                  <br />
                  <div
                    style="margin-bottom: 12px"
                    v-if="
                      authVerify.verify(
                        'deviceManagement-c-add,deviceManagement-c-delete,deviceManagement-c-edit'
                      )
                    "
                  >
                    <el-button
                      size="small"
                      type="primary"
                      @click="addDeviceClassify()"
                      v-auth="'deviceManagement-c-add'"
                      >新增设备分类</el-button
                    >
                    <span style="margin-left: 5px"></span>
                    <el-button
                      size="small"
                      @click="editDeviceClassify()"
                      v-auth="'deviceManagement-c-edit'"
                      >编辑</el-button
                    >
                    <span style="margin-left: 5px"></span>
                    <el-button
                      size="small"
                      @click="deleteChooseDeviceClassify"
                      v-auth="'deviceManagement-c-delete'"
                      >删除</el-button
                    >
                  </div>
                  <template>
                    <div>
                      <a-input-search
                        style="margin-bottom: 8px"
                        placeholder="搜索设备分类"
                        @change="onChange"
                      ></a-input-search>
                      <a-tree
                        :replaceFields="tree_replaceFields"
                        :style="{
                          height: screenHeight - 60 + 'px',
                          overflow: 'auto',
                        }"
                        class="scroll_bar"
                        @expand="onExpand"
                        @select="onSelect"
                        show-icon
                        :expanded-keys="expandedKeys"
                        :auto-expand-parent="autoExpandParent"
                        :tree-data="deviceClassifyData"
                      >
                        <a-icon slot="switcherIcon" type="down" />
                        <a-icon
                          slot="device"
                          type="folder-open"
                          theme="twoTone"
                          two-tone-color="#5AA8FF"
                        />
                        <template slot="title" slot-scope="item">
                          <span v-if="item.name.indexOf(searchValue) > -1">
                            {{
                              item.name.substr(
                                0,
                                item.name.indexOf(searchValue)
                              )
                            }}
                            <span style="color: #f50">{{ searchValue }}</span>
                            {{
                              item.name.substr(
                                item.name.indexOf(searchValue) +
                                  searchValue.length
                              )
                            }}
                          </span>
                          <span v-else>{{ item.name }}</span>
                          <!-- <span style="position: absolute;right: 20px" v-if="treeSelectedKeys.length>0&&treeSelectedKeys[0]===item.id">
                               <a href="#" > <a-icon type="edit" theme="twoTone"
                                                              style="margin-right: 10px"/></a>
                                <a href="#" @click="deleteChooseDeviceClassify">
                           <a-icon type="delete" theme="twoTone" two-tone-color="#eb2f96"/>
                                    </a>
                           </span>-->
                        </template>
                      </a-tree>
                    </div>
                  </template>
                </el-card>
              </a-col>
              <a-col :lg="{ span: 18 }" :xxl="{ span: 20 }">
                <el-card :style="{ 'min-height': screenHeight + 'px' }">
                  <div
                    style="
                      height: 0.8rem;
                      display: flex;
                      justify-content: space-between;
                    "
                  >
                    <span>设备</span>
                    <span style="color: red"
                      >当前查看设备分类: {{ treeSelectedName }}</span
                    >
                  </div>
                  <br />
                  <!-- 按钮 -->
                  <el-button
                    size="small"
                    type="primary"
                    @click="addDevice()"
                    v-auth="'deviceManagement-add'"
                    >新增设备</el-button
                  >
                  <el-button size="small" @click="showDrawer"
                    >按条件搜索</el-button
                  >
                  <br />

                  <div style="margin-bottom: 12px"></div>
                  <!-- 表单 -->
                  <template>
                    <div
                      :style="{
                        height: screenHeight - 120 + 'px',
                        overflow: 'auto',
                      }"
                      class="scroll_bar"
                    >
                      <!--:replaceFields="replaceFields"-->
                      <el-table
        header-row-class-name="my-table-head-style"
                        style="margin-top: 0; margin-bottom: 0"
                        v-loading="spinning"
                        :data="data"
                        row-key="id"
                        border
                      >
                        <el-table-column prop="code" label="编码">
                        </el-table-column>
                        <el-table-column prop="name" label="名称">
                        </el-table-column>
                        <el-table-column prop="model" label="品牌型号">
                        </el-table-column>
                        <el-table-column prop="spec" label="规格">
                        </el-table-column>
                        <el-table-column prop="unit" label="单位">
                        </el-table-column>
                        <el-table-column prop="location" label="所在位置">
                        </el-table-column>
                        <el-table-column
                          prop="deviceClassifyName"
                          label="设备分类"
                        >
                        </el-table-column>
                        <el-table-column prop="num" label="数量">
                        </el-table-column>
                        <el-table-column
                          width="190"
                          label="操作"
                          align="center"
                          v-if="
                            authVerify.verify(
                              'deviceManagement-edit,deviceManagement-delete'
                            )
                          "
                        >
                          <template slot-scope="scope">
                            <el-button
                              type="primary"
                              icon="el-icon-edit-outline"
                              v-auth="'deviceManagement-edit'"
                              size="mini"
                              @click="editDevice(scope.row)"
                              >编辑
                            </el-button>
                            <el-button
                              type="danger"
                              icon="el-icon-delete"
                              size="mini"
                              v-auth="'deviceManagement-delete'"
                              @click="deleteDevice(scope.row)"
                            >
                              删除
                            </el-button>
                          </template>
                        </el-table-column>
                      </el-table>
                      <div class="block">
                        <el-pagination
                          layout="prev, pager, next"
                          :current-page="currentPage"
                          :page-size="pageSize"
                          @current-change="handleCurrentChange"
                          :total="totalCount"
                        >
                        </el-pagination>
                      </div>
                    </div>
                  </template>
                </el-card>
              </a-col>
            </a-row>
          </div>
        </template>
      </div>
    </div>

    <!--添加设备分类-->
    <template>
      <div>
        <a-modal
          v-model="addDeviceClassifyVisible"
          title="新增设备分类"
          width="450px"
        >
          <template slot="footer">
            <a-button @click="addDeviceClassifyVisible = false">取消</a-button>
            <a-button type="primary" @click="toAddDeviceClassify">
              确认
            </a-button>
          </template>
          <a-form-model
            :model="deviceClassifyFormValue"
            ref="addDeviceClassifyRefForm"
            :rules="deviceClassifyRules"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 15 }"
          >
            <a-form-model-item label="名字" prop="name" >
              <a-input v-model="deviceClassifyFormValue.name" allowClear placeholder="请输入名字"/>
            </a-form-model-item>
            <a-form-model-item label="描述" prop="name">
              <a-input v-model="deviceClassifyFormValue.describe" allowClear placeholder="请输入描述"/>
            </a-form-model-item>
            <a-form-model-item label="排序" prop="spec">
              <a-input v-model="deviceClassifyFormValue.sort" allowClear placeholder="请输入排序"/>
            </a-form-model-item>
            <a-form-model-item label="上级分类" prop="unit">
              <a-tree-select
                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                :replaceFields="treeSelectReplaceFields"
                allowClear
                :tree-data="deviceClassifyData"
                placeholder="请选择上级分类名称"
                v-model="deviceClassifyFormValue.parentId"
              >
              </a-tree-select>
            </a-form-model-item>
          </a-form-model>
        </a-modal>
      </div>
    </template>
    <!--编辑设备分类-->
    <template>
      <div>
        <a-modal
          v-model="editDeviceClassifyVisible"
          title="编辑设备分类"
          width="450px"
        >
          <template slot="footer">
            <a-button @click="editDeviceClassifyVisible = false">取消</a-button>
            <a-button type="primary" @click="toEditDeviceClassify">
              确认
            </a-button>
          </template>
          <a-form-model
            :model="deviceClassifyFormValue"
            ref="editDeviceClassifyRefForm"
            :rules="deviceClassifyRules"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 15 }"
          >
            <a-form-model-item label="名字" prop="name">
              <a-input v-model="deviceClassifyFormValue.name" allowClear placeholder="请输入名字" />
            </a-form-model-item>
            <a-form-model-item label="描述" prop="name">
              <a-input v-model="deviceClassifyFormValue.describe" allowClear placeholder="请输入描述"/>
            </a-form-model-item>
            <a-form-model-item label="排序" prop="spec">
              <a-input v-model="deviceClassifyFormValue.sort" allowClear placeholder="请输入排序"/>
            </a-form-model-item>
            <a-form-model-item label="上级分类" prop="unit">
              <a-tree-select
                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                :replaceFields="treeSelectReplaceFields"
                allowClear
                :tree-data="deviceClassifyData"
                placeholder="请选择上级分类名称"
                v-model="deviceClassifyFormValue.parentId"
              >
              </a-tree-select>
            </a-form-model-item>
          </a-form-model>
        </a-modal>
      </div>
    </template>
    <!-- 添加设备 -->
    <template>
      <div>
        <a-modal v-model="addDeviceVisible" title="新增设备" width="800px">
          <template slot="footer">
            <a-button @click="addDeviceVisible = false">取消</a-button>
            <a-button type="primary" @click="toAddDevice"> 确认 </a-button>
          </template>
          <a-form-model
            :model="deviceFormValue"
            ref="addDeviceRefForm"
            :rules="deviceRules"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 15 }"
          >
            <a-row>
              <a-col :span="12">
                <a-form-model-item label="设备编码" prop="code">
                  <a-input v-model="deviceFormValue.code" allowClear placeholder="请输入设备编码"/>
                </a-form-model-item>
                <a-form-model-item label="设备名字" prop="name">
                  <a-input v-model="deviceFormValue.name" allowClear placeholder="请输入设备名字"/>
                </a-form-model-item>
                <a-form-model-item label="设备规格" prop="spec">
                  <a-input v-model="deviceFormValue.spec" allowClear placeholder="请输入设备规格"/>
                </a-form-model-item>
                <a-form-model-item label="设备单位" prop="unit">
                  <a-input v-model="deviceFormValue.unit" allowClear placeholder="请输入设备单位"/>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="品牌型号" prop="model">
                  <a-input v-model="deviceFormValue.model" allowClear placeholder="请输入品牌型号"/>
                </a-form-model-item>
                <a-form-model-item label="所在位置" prop="location">
                  <a-input v-model="deviceFormValue.location" allowClear placeholder="请输入所在位置"/>
                </a-form-model-item>
                <a-form-model-item label="数量" prop="num">
                  <a-input v-model="deviceFormValue.num" type="number" allowClear placeholder="请输入数量"/>
                </a-form-model-item>
                <a-form-model-item label="设备分类" prop="deviceClassify">
                  <a-tree-select
                    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                    :replaceFields="treeSelectReplaceFields"
                    allowClear
                    :tree-data="deviceClassifyData"
                    placeholder="请选择设备分类"
                    v-model="deviceFormValue.deviceClassify"
                  >
                  </a-tree-select>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </a-modal>
      </div>
    </template>
    <!-- 编辑设备 -->
    <template>
      <div>
        <a-modal v-model="editDeviceVisible" title="编辑设备" width="800px">
          <template slot="footer">
            <a-button @click="editDeviceVisible = false">取消</a-button>
            <a-button type="primary" @click="toEditDevice"> 确认 </a-button>
          </template>
          <a-form-model
            :model="deviceFormValue"
            ref="editDeviceRefForm"
            :rules="deviceRules"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 15 }"
          >
            <a-row>
              <a-col :span="12">
                <a-form-model-item label="设备编码" prop="code">
                  <a-input v-model="deviceFormValue.code" allowClear placeholder="请输入设备编码"/>
                </a-form-model-item>
                <a-form-model-item label="设备名字" prop="name">
                  <a-input v-model="deviceFormValue.name" allowClear placeholder="请输入设备名字"/>
                </a-form-model-item>
                <a-form-model-item label="设备规格" prop="spec">
                  <a-input v-model="deviceFormValue.spec" allowClear placeholder="请输入设备规格"/>
                </a-form-model-item>
                <a-form-model-item label="设备单位" prop="unit">
                  <a-input v-model="deviceFormValue.unit" allowClear placeholder="请输入设备单位"/>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="品牌型号" prop="model">
                  <a-input v-model="deviceFormValue.model" allowClear placeholder="请输入品牌型号"/>
                </a-form-model-item>
                <a-form-model-item label="所在位置" prop="location">
                  <a-input v-model="deviceFormValue.location" allowClear placeholder="请输入所在位置"/>
                </a-form-model-item>
                <a-form-model-item label="数量" prop="num">
                  <a-input v-model="deviceFormValue.num" type="number" allowClear placeholder="请输入数量"/>
                </a-form-model-item>
                <a-form-model-item label="设备分类" prop="deviceClassify">
                  <a-tree-select
                    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                    :replaceFields="treeSelectReplaceFields"
                    allowClear
                    :tree-data="deviceClassifyData"
                    placeholder="请选择设备分类"
                    v-model="deviceFormValue.deviceClassify"
                  >
                  </a-tree-select>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </a-modal>
      </div>
    </template>

    <template>
      <div>
        <a-drawer
          title="搜索设备"
          width="520"
          placement="right"
          :closable="false"
          :visible="drawerVisible"
          :after-visible-change="afterVisibleChange"
          @close="onDrawerClose"
        >
          <div>
            <a-form-model
              :model="query"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 16 }"
            >
              <a-row>
                <a-col :span="12">
                  <a-form-model-item label="设备名称" prop="name">
                    <a-input v-model="query.name" allowClear placeholder="请输入设备名称"/>
                  </a-form-model-item>
                </a-col>
                <a-col :span="12">
                  <a-form-model-item label="设备编码" prop="code">
                    <a-input v-model="query.code" allowClear placeholder="请输入设备编码"/>
                  </a-form-model-item>
                </a-col>
              </a-row>
            </a-form-model>
            <div
              :style="{
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'right',
                zIndex: 1,
              }"
            >
              <a-button @click="removeAllQuery">清空</a-button>&nbsp;
              <a-button type="primary" @click="searchQuery">搜索</a-button>
            </div>
          </div>
        </a-drawer>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentPage: 1,
      pageSize: 10,
      totalCount: 0,
      tree_replaceFields: { children: "children", title: "name", key: "id" },
      treeSelectReplaceFields: {
        children: "children",
        title: "name",
        key: "id",
        value: "id",
      },
      query: {
        name: "",
        code: "",
      },
      deviceFormValue: {
        id: "",
        code: "",
        name: "",
        spec: "",
        unit: "",
        location: "",
          model: "",
        num: "",
        deviceClassify: undefined,
      },
      deviceClassifyFormValue: {
        id: "",
        name: "",
        describe: "",
        sort: "",
        parentId: undefined,
      },
      addDeviceClassifyVisible: false,
      editDeviceClassifyVisible: false,
      deviceClassifyRules: {
        name: [
          { required: true, message: "请输入设备分类名字!", trigger: "blur" },
        ],
      },
      deviceRules: {
        code: [{ required: true, message: "请输入设备编码!", trigger: "blur" }],
        name: [{ required: true, message: "请输入设备名字!", trigger: "blur" }],
        deviceClassify: [
          { required: true, message: "请选择设备分类!", trigger: "blur" },
        ],
      },
      deviceClassifyList: [],
      spinning: true,
      screenWidth: document.documentElement.clientWidth, //屏幕宽度
      screenHeight: document.documentElement.clientHeight - 50, //屏幕高度
      drawerVisible: false,
      titleData: [], //标题所有title的数据
      addDeviceVisible: false,
      editDeviceVisible: false,
      expandedKeys: [], //树key
      searchValue: "", //树搜索
      autoExpandParent: true, //树
      searchText: "", //搜索
      searchInput: null, //搜索
      deviceClassifyData: [], //分类树数据
      data: [],
      treeSelectedKeys: [],
      treeSelectedName: "全部",
    };
  },
  watch: {
    screenWidth: function (val) {
      //监听屏幕宽度变化
      console.log(val);
    },
    screenHeight: function (val) {
      //监听屏幕高度变化
      console.log(val);
    },
  },
  mounted() {
    const _this = this;
    window.onresize = function () {
      // 定义窗口大小变更通知事件
      _this.screenWidth = document.documentElement.clientWidth; //窗口宽度
      _this.screenHeight = document.documentElement.clientHeight - 50; //窗口高度
    };
  },
  created() {
    console.log("mounted---" + document.documentElement.clientWidth);
    console.log("mounted---" + document.documentElement.clientHeight);
    this.getDeviceClassifyData();
    this.getDeviceData();
  },
  computed: {
    pageHeight: function () {
      let height = document.documentElement.clientHeight;
      return height - 50;
    },
  },
  methods: {
    deleteChooseDeviceClassify() {
      if (this.treeSelectedKeys.length < 1) {
        this.$message.error("请选中要删除的设备分类!");
        return;
      }
      let self = this;
      this.$confirm({
        title: "确定该设备分类吗?",
        content: "",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          this.http.equipment
            .deleteDeviceClassify({
              id: self.treeSelectedKeys.toString(),
            })
            .then((res) => {
              if (res.data.status == 200) {
                this.$message.success(res.data.msg);
                  self.getDeviceClassifyData();
                self.getDeviceData();
                self.treeSelectedKeys = [];
              } else {
                this.$message.error(res.data.msg);
              }
            });
        },
        onCancel() {},
      });
    },
    toAddDeviceClassify() {
      this.$refs.addDeviceClassifyRefForm.validate((valid) => {
        if (valid) {
          this.http.equipment
            .createDeviceClassify(this.deviceClassifyFormValue)
            .then((response) => {
              if (response.data.status == 200) {
                this.$message.success(response.data.msg);
                this.addDeviceClassifyVisible = false;
              } else {
                this.$message.warning(response.data.msg);
              }
                this.getDeviceClassifyData();
              this.getDeviceData();
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    toEditDeviceClassify() {
      this.$refs.editDeviceClassifyRefForm.validate((valid) => {
        if (valid) {
          this.http.equipment
            .updateDeviceClassify(this.deviceClassifyFormValue)
            .then((response) => {
              if (response.data.status == 200) {
                this.$message.success(response.data.msg);
                this.editDeviceClassifyVisible = false;
              } else {
                this.$message.warning(response.data.msg);
              }
                this.getDeviceClassifyData();
              this.getDeviceData();
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getDeviceData();
    },
    addDeviceClassify() {
      this.cleanDeviceClassify();
      this.addDeviceClassifyVisible = true;
        this.$refs.addDeviceClassifyRefForm.resetFields()
    },
    editDeviceClassify() {
      console.log("开始");
      if (this.treeSelectedKeys.length === 1) {
        this.cleanDeviceClassify();
        console.log("选择id" + this.treeSelectedKeys.toString());
        //回显
        for (let i = 0; i < this.deviceClassifyList.length; i++) {
          console.log("循环中id" + this.deviceClassifyList[i].id);
          if (
            this.deviceClassifyList[i].id == this.treeSelectedKeys.toString()
          ) {
            this.deviceClassifyFormValue.id = this.deviceClassifyList[i].id;
            this.deviceClassifyFormValue.name = this.deviceClassifyList[i].name;
            this.deviceClassifyFormValue.describe =
              this.deviceClassifyList[i].describe;
            this.deviceClassifyFormValue.sort = this.deviceClassifyList[i].sort;
            this.deviceClassifyFormValue.parentId =
              this.deviceClassifyList[i].parentId;
            break;
          }
        }
        this.editDeviceClassifyVisible = true;
          this.$refs.editDeviceClassifyRefForm.resetFields()
      } else {
        this.$message.error("请选择设备分类!");
      }
    },
    cleanDeviceClassify() {
      this.deviceClassifyFormValue = {
        id: "",
        name: "",
        describe: "",
        sort: "",
        parentId: undefined,
      };
      if (this.treeSelectedKeys.length === 1) {
        this.deviceClassifyFormValue.parentId =
          this.treeSelectedKeys.toString();
      }
    },
    editDevice(record) {
      this.cleanOrganization();
      this.deviceFormValue = {
        id: record.id,
        code: record.code,
        name: record.name,
        spec: record.spec,
        unit: record.unit,
        location: record.location,
        model: record.model,
        num: record.num,
        deviceClassify: record.deviceClassify,
      };
      this.editDeviceVisible = true;
        this.$refs.editDeviceRefForm.resetFields()
    },
    addDevice() {
      this.cleanOrganization();
      if (this.treeSelectedKeys.length === 1) {
        this.deviceFormValue.deviceClassify = this.treeSelectedKeys.toString();
      }
      this.addDeviceVisible = true;
        this.$refs.addDeviceRefForm.resetFields();
    },
    cleanOrganization() {
      this.deviceFormValue = {
        id: "",
        code: "",
        name: "",
        spec: "",
        unit: "",
        location: "",
        model: "",
        num: "",
        deviceClassify: undefined,
      };
    },
    toAddDevice() {
      this.$refs.addDeviceRefForm.validate((valid) => {
        if (valid) {
          this.http.equipment
            .createDevice(this.deviceFormValue)
            .then((response) => {
              if (response.data.status == 200) {
                this.$message.success(response.data.msg);
                this.addDeviceVisible = false;
              } else {
                this.$message.warning(response.data.msg);
              }
              this.getDeviceData();
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    toEditDevice() {
      this.$refs.editDeviceRefForm.validate((valid) => {
        if (valid) {
          this.http.equipment
            .updateDevice(this.deviceFormValue)
            .then((response) => {
              if (response.data.status == 200) {
                this.$message.success(response.data.msg);
                this.editDeviceVisible = false;
              } else {
                this.$message.warning(response.data.msg);
              }
              this.getDeviceData();
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    removeAllQuery() {
      this.query = {
        code: "",
        name: "",
      };
      this.searchQuery();
    },
    searchQuery() {
      this.spinning = true;
      this.getDeviceData();
      this.drawerVisible = false;
    },
    deleteDevice(key) {
      let self = this;
      this.$confirm({
        title: "确定删除吗?",
        content: "",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          this.http.equipment
            .deleteDevice({
              id: key.id,
            })
            .then((res) => {
              if (res.data.status == 200) {
                this.$message.success(res.data.msg);
                self.getDeviceData();
              } else {
                this.$message.error(res.data.msg);
              }
            });
        },
        onCancel() {},
      });
    },
    getDeviceData() {
      // pagesize current 搜索的数据  旁边树的id
      let params = {
        size: this.pageSize,
        current: this.currentPage,
        name: this.query.name,
        code: this.query.code,
        deviceClassify: this.treeSelectedKeys[0],
      };
      this.http.equipment.getDeviceListPage(params).then((response) => {
        if (response.data.status == 200) {
          this.spinning = false;
          this.data = response.data.data.records;
          this.totalCount = response.data.data.total;
          this.currentPage = response.data.data.current;
        } else {
          this.$message.warning("数据获取失败");
        }
      });
    },
    getDeviceClassifyData() {
      this.http.equipment.getDeviceClassifyTree({}).then((res) => {
        if (res.data.status == 200) {
          this.deviceClassifyData = res.data.data;
          this.getTitleData(res.data.data);
        } else {
          this.$message.warning("数据获取失败");
        }
      });
      this.http.equipment.getDeviceClassifyList({}).then((res) => {
        if (res.data.status == 200) {
          this.deviceClassifyList = res.data.data;
        } else {
          this.$message.warning("数据获取失败");
        }
      });
    },
    showDrawer() {
      this.drawerVisible = true;
    },
    onDrawerClose() {
      this.drawerVisible = false;
    },
    afterVisibleChange() {},
    //树依赖方法
    //树   展开节点的时候触发
    onExpand(expandedKeys) {
      console.log(expandedKeys);
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    //当点击选择的时候
    onSelect(selectedKeys) {
      console.log(selectedKeys);
      this.treeSelectedKeys = selectedKeys;
      this.getDeviceData();
      if (selectedKeys.length === 0) {
        this.treeSelectedName = "全部";
      } else {
        for (let deviceClassify of this.deviceClassifyList) {
          if (deviceClassify.id === selectedKeys[0]) {
            this.treeSelectedName = deviceClassify.name;
            break;
          }
        }
      }
    },
    onChange(e) {
      console.log("搜索中");
      const value = e.target.value;
      if (value == "") {
        this.expandedKeys = [];
        this.searchValue = "";
      } else {
        const expandedKeys = this.titleData
          .map((item) => {
            if (item.title.indexOf(value) > -1) {
              return this.getParentKey(item.title, this.deviceClassifyData);
            }
            return null;
          })
          .filter((item, i, self) => item && self.indexOf(item) === i);
        Object.assign(this, {
          expandedKeys,
          searchValue: value,
          autoExpandParent: true,
        });
      }
    },
    //树 获取父id 去展开
    getParentKey(title, tree) {
      let parentKey;
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.children) {
          //注意
          if (node.children.some((item) => item.name === title)) {
            //注意
            parentKey = node.id;
          } else if (this.getParentKey(title, node.children)) {
            parentKey = this.getParentKey(title, node.children);
          }
        }
      }
      return parentKey;
    },
    //获取树的所有节点值
    getTitleData(data) {
      for (let i = 0; i < data.length; i++) {
        const node = data[i];
        //注意
        const title = node.name;
        this.titleData.push({
          title: title,
        });
        if (node.children) {
          this.getTitleData(node.children);
        }
      }
    },
    //树依赖方法结束
  },
};
</script>

<style scoped>
/*滚动条整体样式*/
.scroll_bar::-webkit-scrollbar {
  width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
}

.scroll_bar::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background-color: #cecece;
}

.scroll_bar::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #ededed;
}
</style>
